import { default as abouteXm5xFsJnQMeta } from "/webapp/pages/about/about.vue?macro=true";
import { default as boardDirectorsJiLMMUbOnVMeta } from "/webapp/pages/about/boardDirectors.vue?macro=true";
import { default as corporateProfileztcjAJWfNYMeta } from "/webapp/pages/about/corporateProfile.vue?macro=true";
import { default as facilitiesPhl7W1hIU5Meta } from "/webapp/pages/about/facilities.vue?macro=true";
import { default as managementsuLOp8yb0TMeta } from "/webapp/pages/about/management.vue?macro=true";
import { default as aboutKzC5DIwjlUMeta } from "/webapp/pages/about.vue?macro=true";
import { default as contactH3yvUcSxPCMeta } from "/webapp/pages/contact.vue?macro=true";
import { default as indexxwoz2jbduuMeta } from "/webapp/pages/index.vue?macro=true";
import { default as informationRequestConfirmationPqJ94nznN4Meta } from "/webapp/pages/informationRequestConfirmation.vue?macro=true";
import { default as corporateGovernanceqNTGj3uu9JMeta } from "/webapp/pages/investorRelations/corporateGovernance.vue?macro=true";
import { default as dividendDripPolicies4fld4J1swVMeta } from "/webapp/pages/investorRelations/dividendDripPolicies.vue?macro=true";
import { default as emailAlertsN4JjTr3Jf8Meta } from "/webapp/pages/investorRelations/emailAlerts.vue?macro=true";
import { default as faqP5twfG3seoMeta } from "/webapp/pages/investorRelations/faq.vue?macro=true";
import { default as annualInformationFormsx6fnmRdAtSMeta } from "/webapp/pages/investorRelations/financialDocuments/annualInformationForms.vue?macro=true";
import { default as annualReports3l0DaXp0gBMeta } from "/webapp/pages/investorRelations/financialDocuments/annualReports.vue?macro=true";
import { default as managementProxyCircularsW511ydBeSNMeta } from "/webapp/pages/investorRelations/financialDocuments/managementProxyCirculars.vue?macro=true";
import { default as _91year_93SdDHrTaQrjMeta } from "/webapp/pages/investorRelations/financialDocuments/presentationsWebcasts/[year].vue?macro=true";
import { default as indexQm9fGnZFiEMeta } from "/webapp/pages/investorRelations/financialDocuments/presentationsWebcasts/index.vue?macro=true";
import { default as quarterlyReportsEakGihW5vuMeta } from "/webapp/pages/investorRelations/financialDocuments/quarterlyReports.vue?macro=true";
import { default as governanceCommitteeCharterAPS1Xl5PQWMeta } from "/webapp/pages/investorRelations/governanceCommitteeCharter.vue?macro=true";
import { default as investorRelationssXH5FIbYViMeta } from "/webapp/pages/investorRelations/investorRelations.vue?macro=true";
import { default as irContactxGM3O2kPfhMeta } from "/webapp/pages/investorRelations/irContact.vue?macro=true";
import { default as shareholderInformationqTIgHSASuCMeta } from "/webapp/pages/investorRelations/shareholderInformation.vue?macro=true";
import { default as stockChartpNeHE8z1AYMeta } from "/webapp/pages/investorRelations/stockChart.vue?macro=true";
import { default as transfertAgentAuditorEZ6lx7Avv7Meta } from "/webapp/pages/investorRelations/transfertAgentAuditor.vue?macro=true";
import { default as _91year_939dU7gupdeyMeta } from "/webapp/pages/newsEvents/pressReleases/[year].vue?macro=true";
import { default as index9D1bkFe18qMeta } from "/webapp/pages/newsEvents/pressReleases/index.vue?macro=true";
import { default as officialSubscriptionConfirmationPagetjJEGE3iayMeta } from "/webapp/pages/officialSubscriptionConfirmationPage.vue?macro=true";
import { default as onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta } from "/webapp/pages/onSiteSubscriptionConfirmationPage.vue?macro=true";
import { default as awayFromHomeMarketxP17CBd3kiMeta } from "/webapp/pages/products/awayFromHomeMarket.vue?macro=true";
import { default as consumerMarketdLuEfUAR7bMeta } from "/webapp/pages/products/consumerMarket.vue?macro=true";
import { default as privateLabelMarketoPBRfrQQGTMeta } from "/webapp/pages/products/privateLabelMarket.vue?macro=true";
import { default as productsdOqbioQb2vMeta } from "/webapp/pages/products/products.vue?macro=true";
import { default as productshvsMC217XqMeta } from "/webapp/pages/products.vue?macro=true";
import { default as sitemapGxgpUpZGsgMeta } from "/webapp/pages/sitemap.vue?macro=true";
import { default as sustainableDevelopment4GiT3UHmhEMeta } from "/webapp/pages/sustainability/sustainableDevelopment.vue?macro=true";
import { default as termsConditionsQwoYMgqO0RMeta } from "/webapp/pages/termsConditions.vue?macro=true";
import { default as unsubscribeConfirmationPagewU9zZPQ69BMeta } from "/webapp/pages/unsubscribeConfirmationPage.vue?macro=true";
import { default as vote5RMIYNWZTCMeta } from "/webapp/pages/vote.vue?macro=true";
export default [
  {
    name: aboutKzC5DIwjlUMeta?.name ?? "about___en-CA",
    path: aboutKzC5DIwjlUMeta?.path ?? "/about",
    children: [
  {
    name: abouteXm5xFsJnQMeta?.name ?? "about-about___en-CA",
    path: abouteXm5xFsJnQMeta?.path ?? "/about",
    meta: abouteXm5xFsJnQMeta || {},
    alias: abouteXm5xFsJnQMeta?.alias || [],
    redirect: abouteXm5xFsJnQMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about/about.vue").then(m => m.default || m)
  },
  {
    name: boardDirectorsJiLMMUbOnVMeta?.name ?? "about-boardDirectors___en-CA",
    path: boardDirectorsJiLMMUbOnVMeta?.path ?? "/about/board-directors",
    meta: boardDirectorsJiLMMUbOnVMeta || {},
    alias: boardDirectorsJiLMMUbOnVMeta?.alias || [],
    redirect: boardDirectorsJiLMMUbOnVMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about/boardDirectors.vue").then(m => m.default || m)
  },
  {
    name: corporateProfileztcjAJWfNYMeta?.name ?? "about-corporateProfile___en-CA",
    path: corporateProfileztcjAJWfNYMeta?.path ?? "/about/corporate-profile",
    meta: corporateProfileztcjAJWfNYMeta || {},
    alias: corporateProfileztcjAJWfNYMeta?.alias || [],
    redirect: corporateProfileztcjAJWfNYMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about/corporateProfile.vue").then(m => m.default || m)
  },
  {
    name: facilitiesPhl7W1hIU5Meta?.name ?? "about-facilities___en-CA",
    path: facilitiesPhl7W1hIU5Meta?.path ?? "/about/facilities",
    meta: facilitiesPhl7W1hIU5Meta || {},
    alias: facilitiesPhl7W1hIU5Meta?.alias || [],
    redirect: facilitiesPhl7W1hIU5Meta?.redirect || undefined,
    component: () => import("/webapp/pages/about/facilities.vue").then(m => m.default || m)
  },
  {
    name: managementsuLOp8yb0TMeta?.name ?? "about-management___en-CA",
    path: managementsuLOp8yb0TMeta?.path ?? "/about/management",
    meta: managementsuLOp8yb0TMeta || {},
    alias: managementsuLOp8yb0TMeta?.alias || [],
    redirect: managementsuLOp8yb0TMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about/management.vue").then(m => m.default || m)
  }
],
    meta: aboutKzC5DIwjlUMeta || {},
    alias: aboutKzC5DIwjlUMeta?.alias || [],
    redirect: aboutKzC5DIwjlUMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutKzC5DIwjlUMeta?.name ?? "about___fr-CA",
    path: aboutKzC5DIwjlUMeta?.path ?? "/fr-CA/about",
    children: [
  {
    name: abouteXm5xFsJnQMeta?.name ?? "about-about___fr-CA",
    path: abouteXm5xFsJnQMeta?.path ?? "/fr-CA/a-propos-kpt",
    meta: abouteXm5xFsJnQMeta || {},
    alias: abouteXm5xFsJnQMeta?.alias || [],
    redirect: abouteXm5xFsJnQMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about/about.vue").then(m => m.default || m)
  },
  {
    name: boardDirectorsJiLMMUbOnVMeta?.name ?? "about-boardDirectors___fr-CA",
    path: boardDirectorsJiLMMUbOnVMeta?.path ?? "/fr-CA/a-propos-kpt/conseil-administration",
    meta: boardDirectorsJiLMMUbOnVMeta || {},
    alias: boardDirectorsJiLMMUbOnVMeta?.alias || [],
    redirect: boardDirectorsJiLMMUbOnVMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about/boardDirectors.vue").then(m => m.default || m)
  },
  {
    name: corporateProfileztcjAJWfNYMeta?.name ?? "about-corporateProfile___fr-CA",
    path: corporateProfileztcjAJWfNYMeta?.path ?? "/fr-CA/a-propos-kpt/profile",
    meta: corporateProfileztcjAJWfNYMeta || {},
    alias: corporateProfileztcjAJWfNYMeta?.alias || [],
    redirect: corporateProfileztcjAJWfNYMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about/corporateProfile.vue").then(m => m.default || m)
  },
  {
    name: facilitiesPhl7W1hIU5Meta?.name ?? "about-facilities___fr-CA",
    path: facilitiesPhl7W1hIU5Meta?.path ?? "/fr-CA/a-propos-kpt/usines",
    meta: facilitiesPhl7W1hIU5Meta || {},
    alias: facilitiesPhl7W1hIU5Meta?.alias || [],
    redirect: facilitiesPhl7W1hIU5Meta?.redirect || undefined,
    component: () => import("/webapp/pages/about/facilities.vue").then(m => m.default || m)
  },
  {
    name: managementsuLOp8yb0TMeta?.name ?? "about-management___fr-CA",
    path: managementsuLOp8yb0TMeta?.path ?? "/fr-CA/a-propos-kpt/direction",
    meta: managementsuLOp8yb0TMeta || {},
    alias: managementsuLOp8yb0TMeta?.alias || [],
    redirect: managementsuLOp8yb0TMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about/management.vue").then(m => m.default || m)
  }
],
    meta: aboutKzC5DIwjlUMeta || {},
    alias: aboutKzC5DIwjlUMeta?.alias || [],
    redirect: aboutKzC5DIwjlUMeta?.redirect || undefined,
    component: () => import("/webapp/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactH3yvUcSxPCMeta?.name ?? "contact___en-CA",
    path: contactH3yvUcSxPCMeta?.path ?? "/contact",
    meta: contactH3yvUcSxPCMeta || {},
    alias: contactH3yvUcSxPCMeta?.alias || [],
    redirect: contactH3yvUcSxPCMeta?.redirect || undefined,
    component: () => import("/webapp/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactH3yvUcSxPCMeta?.name ?? "contact___fr-CA",
    path: contactH3yvUcSxPCMeta?.path ?? "/fr-CA/contactez-nous",
    meta: contactH3yvUcSxPCMeta || {},
    alias: contactH3yvUcSxPCMeta?.alias || [],
    redirect: contactH3yvUcSxPCMeta?.redirect || undefined,
    component: () => import("/webapp/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexxwoz2jbduuMeta?.name ?? "index___en-CA",
    path: indexxwoz2jbduuMeta?.path ?? "/home",
    meta: indexxwoz2jbduuMeta || {},
    alias: indexxwoz2jbduuMeta?.alias || [],
    redirect: indexxwoz2jbduuMeta?.redirect || undefined,
    component: () => import("/webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxwoz2jbduuMeta?.name ?? "index___fr-CA",
    path: indexxwoz2jbduuMeta?.path ?? "/fr-CA/accueil",
    meta: indexxwoz2jbduuMeta || {},
    alias: indexxwoz2jbduuMeta?.alias || [],
    redirect: indexxwoz2jbduuMeta?.redirect || undefined,
    component: () => import("/webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: informationRequestConfirmationPqJ94nznN4Meta?.name ?? "informationRequestConfirmation___en-CA",
    path: informationRequestConfirmationPqJ94nznN4Meta?.path ?? "/information-request-confirmation",
    meta: informationRequestConfirmationPqJ94nznN4Meta || {},
    alias: informationRequestConfirmationPqJ94nznN4Meta?.alias || [],
    redirect: informationRequestConfirmationPqJ94nznN4Meta?.redirect || undefined,
    component: () => import("/webapp/pages/informationRequestConfirmation.vue").then(m => m.default || m)
  },
  {
    name: informationRequestConfirmationPqJ94nznN4Meta?.name ?? "informationRequestConfirmation___fr-CA",
    path: informationRequestConfirmationPqJ94nznN4Meta?.path ?? "/fr-CA/confirmation-demande-information",
    meta: informationRequestConfirmationPqJ94nznN4Meta || {},
    alias: informationRequestConfirmationPqJ94nznN4Meta?.alias || [],
    redirect: informationRequestConfirmationPqJ94nznN4Meta?.redirect || undefined,
    component: () => import("/webapp/pages/informationRequestConfirmation.vue").then(m => m.default || m)
  },
  {
    name: corporateGovernanceqNTGj3uu9JMeta?.name ?? "investorRelations-corporateGovernance___en-CA",
    path: corporateGovernanceqNTGj3uu9JMeta?.path ?? "/investor-relations/corporate-governance",
    meta: corporateGovernanceqNTGj3uu9JMeta || {},
    alias: corporateGovernanceqNTGj3uu9JMeta?.alias || [],
    redirect: corporateGovernanceqNTGj3uu9JMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/corporateGovernance.vue").then(m => m.default || m)
  },
  {
    name: corporateGovernanceqNTGj3uu9JMeta?.name ?? "investorRelations-corporateGovernance___fr-CA",
    path: corporateGovernanceqNTGj3uu9JMeta?.path ?? "/fr-CA/relations-avec-investisseurs/gouvernance-entreprise",
    meta: corporateGovernanceqNTGj3uu9JMeta || {},
    alias: corporateGovernanceqNTGj3uu9JMeta?.alias || [],
    redirect: corporateGovernanceqNTGj3uu9JMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/corporateGovernance.vue").then(m => m.default || m)
  },
  {
    name: dividendDripPolicies4fld4J1swVMeta?.name ?? "investorRelations-dividendDripPolicies___en-CA",
    path: dividendDripPolicies4fld4J1swVMeta?.path ?? "/investor-relations/shareholder-information/dividend-drip-policies",
    meta: dividendDripPolicies4fld4J1swVMeta || {},
    alias: dividendDripPolicies4fld4J1swVMeta?.alias || [],
    redirect: dividendDripPolicies4fld4J1swVMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/dividendDripPolicies.vue").then(m => m.default || m)
  },
  {
    name: dividendDripPolicies4fld4J1swVMeta?.name ?? "investorRelations-dividendDripPolicies___fr-CA",
    path: dividendDripPolicies4fld4J1swVMeta?.path ?? "/fr-CA/relations-avec-investisseurs/section-investisseurs/politiques-relatives-aux-dividendes-au-prd",
    meta: dividendDripPolicies4fld4J1swVMeta || {},
    alias: dividendDripPolicies4fld4J1swVMeta?.alias || [],
    redirect: dividendDripPolicies4fld4J1swVMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/dividendDripPolicies.vue").then(m => m.default || m)
  },
  {
    name: emailAlertsN4JjTr3Jf8Meta?.name ?? "investorRelations-emailAlerts___en-CA",
    path: emailAlertsN4JjTr3Jf8Meta?.path ?? "/investor-relations/email-alerts",
    meta: emailAlertsN4JjTr3Jf8Meta || {},
    alias: emailAlertsN4JjTr3Jf8Meta?.alias || [],
    redirect: emailAlertsN4JjTr3Jf8Meta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/emailAlerts.vue").then(m => m.default || m)
  },
  {
    name: emailAlertsN4JjTr3Jf8Meta?.name ?? "investorRelations-emailAlerts___fr-CA",
    path: emailAlertsN4JjTr3Jf8Meta?.path ?? "/fr-CA/relations-avec-investisseurs/alertes-courriel",
    meta: emailAlertsN4JjTr3Jf8Meta || {},
    alias: emailAlertsN4JjTr3Jf8Meta?.alias || [],
    redirect: emailAlertsN4JjTr3Jf8Meta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/emailAlerts.vue").then(m => m.default || m)
  },
  {
    name: faqP5twfG3seoMeta?.name ?? "investorRelations-faq___en-CA",
    path: faqP5twfG3seoMeta?.path ?? "/investor-relations/shareholder-information/faq",
    meta: faqP5twfG3seoMeta || {},
    alias: faqP5twfG3seoMeta?.alias || [],
    redirect: faqP5twfG3seoMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/faq.vue").then(m => m.default || m)
  },
  {
    name: faqP5twfG3seoMeta?.name ?? "investorRelations-faq___fr-CA",
    path: faqP5twfG3seoMeta?.path ?? "/fr-CA/relations-avec-investisseurs/section-investisseurs/faq",
    meta: faqP5twfG3seoMeta || {},
    alias: faqP5twfG3seoMeta?.alias || [],
    redirect: faqP5twfG3seoMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/faq.vue").then(m => m.default || m)
  },
  {
    name: annualInformationFormsx6fnmRdAtSMeta?.name ?? "investorRelations-financialDocuments-annualInformationForms___en-CA",
    path: annualInformationFormsx6fnmRdAtSMeta?.path ?? "/investor-relations/financial-documents/annual-information-forms",
    meta: annualInformationFormsx6fnmRdAtSMeta || {},
    alias: annualInformationFormsx6fnmRdAtSMeta?.alias || [],
    redirect: annualInformationFormsx6fnmRdAtSMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/annualInformationForms.vue").then(m => m.default || m)
  },
  {
    name: annualInformationFormsx6fnmRdAtSMeta?.name ?? "investorRelations-financialDocuments-annualInformationForms___fr-CA",
    path: annualInformationFormsx6fnmRdAtSMeta?.path ?? "/fr-CA/relations-avec-investisseurs/documents-financiers/formulaires-information-annuels",
    meta: annualInformationFormsx6fnmRdAtSMeta || {},
    alias: annualInformationFormsx6fnmRdAtSMeta?.alias || [],
    redirect: annualInformationFormsx6fnmRdAtSMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/annualInformationForms.vue").then(m => m.default || m)
  },
  {
    name: annualReports3l0DaXp0gBMeta?.name ?? "investorRelations-financialDocuments-annualReports___en-CA",
    path: annualReports3l0DaXp0gBMeta?.path ?? "/investor-relations/financial-documents/annual-reports",
    meta: annualReports3l0DaXp0gBMeta || {},
    alias: annualReports3l0DaXp0gBMeta?.alias || [],
    redirect: annualReports3l0DaXp0gBMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/annualReports.vue").then(m => m.default || m)
  },
  {
    name: annualReports3l0DaXp0gBMeta?.name ?? "investorRelations-financialDocuments-annualReports___fr-CA",
    path: annualReports3l0DaXp0gBMeta?.path ?? "/fr-CA/relations-avec-investisseurs/documents-financiers/rapport-annuel",
    meta: annualReports3l0DaXp0gBMeta || {},
    alias: annualReports3l0DaXp0gBMeta?.alias || [],
    redirect: annualReports3l0DaXp0gBMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/annualReports.vue").then(m => m.default || m)
  },
  {
    name: managementProxyCircularsW511ydBeSNMeta?.name ?? "investorRelations-financialDocuments-managementProxyCirculars___en-CA",
    path: managementProxyCircularsW511ydBeSNMeta?.path ?? "/investor-relations/financial-documents/management-proxy-circulars",
    meta: managementProxyCircularsW511ydBeSNMeta || {},
    alias: managementProxyCircularsW511ydBeSNMeta?.alias || [],
    redirect: managementProxyCircularsW511ydBeSNMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/managementProxyCirculars.vue").then(m => m.default || m)
  },
  {
    name: managementProxyCircularsW511ydBeSNMeta?.name ?? "investorRelations-financialDocuments-managementProxyCirculars___fr-CA",
    path: managementProxyCircularsW511ydBeSNMeta?.path ?? "/fr-CA/relations-avec-investisseurs/documents-financiers/circulaires-sollicitation-procurations-direction",
    meta: managementProxyCircularsW511ydBeSNMeta || {},
    alias: managementProxyCircularsW511ydBeSNMeta?.alias || [],
    redirect: managementProxyCircularsW511ydBeSNMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/managementProxyCirculars.vue").then(m => m.default || m)
  },
  {
    name: _91year_93SdDHrTaQrjMeta?.name ?? "investorRelations-financialDocuments-presentationsWebcasts-year___en-CA",
    path: _91year_93SdDHrTaQrjMeta?.path ?? "/investor-relations/financial-documents/presentations-webcasts/:year",
    meta: _91year_93SdDHrTaQrjMeta || {},
    alias: _91year_93SdDHrTaQrjMeta?.alias || [],
    redirect: _91year_93SdDHrTaQrjMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/presentationsWebcasts/[year].vue").then(m => m.default || m)
  },
  {
    name: _91year_93SdDHrTaQrjMeta?.name ?? "investorRelations-financialDocuments-presentationsWebcasts-year___fr-CA",
    path: _91year_93SdDHrTaQrjMeta?.path ?? "/fr-CA/relations-avec-investisseurs/documents-financiers/presentations-webcasts/:year",
    meta: _91year_93SdDHrTaQrjMeta || {},
    alias: _91year_93SdDHrTaQrjMeta?.alias || [],
    redirect: _91year_93SdDHrTaQrjMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/presentationsWebcasts/[year].vue").then(m => m.default || m)
  },
  {
    name: indexQm9fGnZFiEMeta?.name ?? "investorRelations-financialDocuments-presentationsWebcasts___en-CA",
    path: indexQm9fGnZFiEMeta?.path ?? "/investor-relations/financial-documents/presentations-webcasts",
    meta: indexQm9fGnZFiEMeta || {},
    alias: indexQm9fGnZFiEMeta?.alias || [],
    redirect: indexQm9fGnZFiEMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/presentationsWebcasts/index.vue").then(m => m.default || m)
  },
  {
    name: indexQm9fGnZFiEMeta?.name ?? "investorRelations-financialDocuments-presentationsWebcasts___fr-CA",
    path: indexQm9fGnZFiEMeta?.path ?? "/fr-CA/relations-avec-investisseurs/documents-financiers/presentations-webcasts",
    meta: indexQm9fGnZFiEMeta || {},
    alias: indexQm9fGnZFiEMeta?.alias || [],
    redirect: indexQm9fGnZFiEMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/presentationsWebcasts/index.vue").then(m => m.default || m)
  },
  {
    name: quarterlyReportsEakGihW5vuMeta?.name ?? "investorRelations-financialDocuments-quarterlyReports___en-CA",
    path: quarterlyReportsEakGihW5vuMeta?.path ?? "/investor-relations/financial-documents/quarterly-reports",
    meta: quarterlyReportsEakGihW5vuMeta || {},
    alias: quarterlyReportsEakGihW5vuMeta?.alias || [],
    redirect: quarterlyReportsEakGihW5vuMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/quarterlyReports.vue").then(m => m.default || m)
  },
  {
    name: quarterlyReportsEakGihW5vuMeta?.name ?? "investorRelations-financialDocuments-quarterlyReports___fr-CA",
    path: quarterlyReportsEakGihW5vuMeta?.path ?? "/fr-CA/relations-avec-investisseurs/documents-financiers/rapports-trimestriels",
    meta: quarterlyReportsEakGihW5vuMeta || {},
    alias: quarterlyReportsEakGihW5vuMeta?.alias || [],
    redirect: quarterlyReportsEakGihW5vuMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/financialDocuments/quarterlyReports.vue").then(m => m.default || m)
  },
  {
    name: governanceCommitteeCharterAPS1Xl5PQWMeta?.name ?? "investorRelations-governanceCommitteeCharter___en-CA",
    path: governanceCommitteeCharterAPS1Xl5PQWMeta?.path ?? "/investor-relations/corporate-governance/governance-committee-charter",
    meta: governanceCommitteeCharterAPS1Xl5PQWMeta || {},
    alias: governanceCommitteeCharterAPS1Xl5PQWMeta?.alias || [],
    redirect: governanceCommitteeCharterAPS1Xl5PQWMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/governanceCommitteeCharter.vue").then(m => m.default || m)
  },
  {
    name: governanceCommitteeCharterAPS1Xl5PQWMeta?.name ?? "investorRelations-governanceCommitteeCharter___fr-CA",
    path: governanceCommitteeCharterAPS1Xl5PQWMeta?.path ?? "/fr-CA/relations-avec-investisseurs/gouvernance-entreprise/charte-comite-gouvernance",
    meta: governanceCommitteeCharterAPS1Xl5PQWMeta || {},
    alias: governanceCommitteeCharterAPS1Xl5PQWMeta?.alias || [],
    redirect: governanceCommitteeCharterAPS1Xl5PQWMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/governanceCommitteeCharter.vue").then(m => m.default || m)
  },
  {
    name: investorRelationssXH5FIbYViMeta?.name ?? "investorRelations-investorRelations___en-CA",
    path: investorRelationssXH5FIbYViMeta?.path ?? "/investor-relations",
    meta: investorRelationssXH5FIbYViMeta || {},
    alias: investorRelationssXH5FIbYViMeta?.alias || [],
    redirect: investorRelationssXH5FIbYViMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/investorRelations.vue").then(m => m.default || m)
  },
  {
    name: investorRelationssXH5FIbYViMeta?.name ?? "investorRelations-investorRelations___fr-CA",
    path: investorRelationssXH5FIbYViMeta?.path ?? "/fr-CA/relations-avec-investisseurs",
    meta: investorRelationssXH5FIbYViMeta || {},
    alias: investorRelationssXH5FIbYViMeta?.alias || [],
    redirect: investorRelationssXH5FIbYViMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/investorRelations.vue").then(m => m.default || m)
  },
  {
    name: irContactxGM3O2kPfhMeta?.name ?? "investorRelations-irContact___en-CA",
    path: irContactxGM3O2kPfhMeta?.path ?? "/investor-relations/shareholder-information/ir-contact",
    meta: irContactxGM3O2kPfhMeta || {},
    alias: irContactxGM3O2kPfhMeta?.alias || [],
    redirect: irContactxGM3O2kPfhMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/irContact.vue").then(m => m.default || m)
  },
  {
    name: irContactxGM3O2kPfhMeta?.name ?? "investorRelations-irContact___fr-CA",
    path: irContactxGM3O2kPfhMeta?.path ?? "/fr-CA/relations-avec-investisseurs/section-investisseurs/information-aux-investisseurs",
    meta: irContactxGM3O2kPfhMeta || {},
    alias: irContactxGM3O2kPfhMeta?.alias || [],
    redirect: irContactxGM3O2kPfhMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/irContact.vue").then(m => m.default || m)
  },
  {
    name: shareholderInformationqTIgHSASuCMeta?.name ?? "investorRelations-shareholderInformation___en-CA",
    path: shareholderInformationqTIgHSASuCMeta?.path ?? "/investor-relations/shareholder-information",
    meta: shareholderInformationqTIgHSASuCMeta || {},
    alias: shareholderInformationqTIgHSASuCMeta?.alias || [],
    redirect: shareholderInformationqTIgHSASuCMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/shareholderInformation.vue").then(m => m.default || m)
  },
  {
    name: shareholderInformationqTIgHSASuCMeta?.name ?? "investorRelations-shareholderInformation___fr-CA",
    path: shareholderInformationqTIgHSASuCMeta?.path ?? "/fr-CA/relations-avec-investisseurs/section-investisseurs",
    meta: shareholderInformationqTIgHSASuCMeta || {},
    alias: shareholderInformationqTIgHSASuCMeta?.alias || [],
    redirect: shareholderInformationqTIgHSASuCMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/shareholderInformation.vue").then(m => m.default || m)
  },
  {
    name: stockChartpNeHE8z1AYMeta?.name ?? "investorRelations-stockChart___en-CA",
    path: stockChartpNeHE8z1AYMeta?.path ?? "/investor-relations/shareholder-information/stock-chart",
    meta: stockChartpNeHE8z1AYMeta || {},
    alias: stockChartpNeHE8z1AYMeta?.alias || [],
    redirect: stockChartpNeHE8z1AYMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/stockChart.vue").then(m => m.default || m)
  },
  {
    name: stockChartpNeHE8z1AYMeta?.name ?? "investorRelations-stockChart___fr-CA",
    path: stockChartpNeHE8z1AYMeta?.path ?? "/fr-CA/relations-avec-investisseurs/section-investisseurs/graphiques-cours-action",
    meta: stockChartpNeHE8z1AYMeta || {},
    alias: stockChartpNeHE8z1AYMeta?.alias || [],
    redirect: stockChartpNeHE8z1AYMeta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/stockChart.vue").then(m => m.default || m)
  },
  {
    name: transfertAgentAuditorEZ6lx7Avv7Meta?.name ?? "investorRelations-transfertAgentAuditor___en-CA",
    path: transfertAgentAuditorEZ6lx7Avv7Meta?.path ?? "/investor-relations/shareholder-information/transfer-agent-auditor",
    meta: transfertAgentAuditorEZ6lx7Avv7Meta || {},
    alias: transfertAgentAuditorEZ6lx7Avv7Meta?.alias || [],
    redirect: transfertAgentAuditorEZ6lx7Avv7Meta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/transfertAgentAuditor.vue").then(m => m.default || m)
  },
  {
    name: transfertAgentAuditorEZ6lx7Avv7Meta?.name ?? "investorRelations-transfertAgentAuditor___fr-CA",
    path: transfertAgentAuditorEZ6lx7Avv7Meta?.path ?? "/fr-CA/relations-avec-investisseurs/section-investisseurs/agent-transfert-auditeur",
    meta: transfertAgentAuditorEZ6lx7Avv7Meta || {},
    alias: transfertAgentAuditorEZ6lx7Avv7Meta?.alias || [],
    redirect: transfertAgentAuditorEZ6lx7Avv7Meta?.redirect || undefined,
    component: () => import("/webapp/pages/investorRelations/transfertAgentAuditor.vue").then(m => m.default || m)
  },
  {
    name: _91year_939dU7gupdeyMeta?.name ?? "newsEvents-pressReleases-year___en-CA",
    path: _91year_939dU7gupdeyMeta?.path ?? "/news-events/press-releases/:year",
    meta: _91year_939dU7gupdeyMeta || {},
    alias: _91year_939dU7gupdeyMeta?.alias || [],
    redirect: _91year_939dU7gupdeyMeta?.redirect || undefined,
    component: () => import("/webapp/pages/newsEvents/pressReleases/[year].vue").then(m => m.default || m)
  },
  {
    name: _91year_939dU7gupdeyMeta?.name ?? "newsEvents-pressReleases-year___fr-CA",
    path: _91year_939dU7gupdeyMeta?.path ?? "/fr-CA/nouvelles-evenements/communiques-presse/:year",
    meta: _91year_939dU7gupdeyMeta || {},
    alias: _91year_939dU7gupdeyMeta?.alias || [],
    redirect: _91year_939dU7gupdeyMeta?.redirect || undefined,
    component: () => import("/webapp/pages/newsEvents/pressReleases/[year].vue").then(m => m.default || m)
  },
  {
    name: index9D1bkFe18qMeta?.name ?? "newsEvents-pressReleases___en-CA",
    path: index9D1bkFe18qMeta?.path ?? "/news-events/press-releases",
    meta: index9D1bkFe18qMeta || {},
    alias: index9D1bkFe18qMeta?.alias || [],
    redirect: index9D1bkFe18qMeta?.redirect || undefined,
    component: () => import("/webapp/pages/newsEvents/pressReleases/index.vue").then(m => m.default || m)
  },
  {
    name: index9D1bkFe18qMeta?.name ?? "newsEvents-pressReleases___fr-CA",
    path: index9D1bkFe18qMeta?.path ?? "/fr-CA/nouvelles-evenements/communiques-presse",
    meta: index9D1bkFe18qMeta || {},
    alias: index9D1bkFe18qMeta?.alias || [],
    redirect: index9D1bkFe18qMeta?.redirect || undefined,
    component: () => import("/webapp/pages/newsEvents/pressReleases/index.vue").then(m => m.default || m)
  },
  {
    name: officialSubscriptionConfirmationPagetjJEGE3iayMeta?.name ?? "officialSubscriptionConfirmationPage___en-CA",
    path: officialSubscriptionConfirmationPagetjJEGE3iayMeta?.path ?? "/official-subscription-confirmation-page",
    meta: officialSubscriptionConfirmationPagetjJEGE3iayMeta || {},
    alias: officialSubscriptionConfirmationPagetjJEGE3iayMeta?.alias || [],
    redirect: officialSubscriptionConfirmationPagetjJEGE3iayMeta?.redirect || undefined,
    component: () => import("/webapp/pages/officialSubscriptionConfirmationPage.vue").then(m => m.default || m)
  },
  {
    name: officialSubscriptionConfirmationPagetjJEGE3iayMeta?.name ?? "officialSubscriptionConfirmationPage___fr-CA",
    path: officialSubscriptionConfirmationPagetjJEGE3iayMeta?.path ?? "/fr-CA/page-confirmation-officielle-abonnement",
    meta: officialSubscriptionConfirmationPagetjJEGE3iayMeta || {},
    alias: officialSubscriptionConfirmationPagetjJEGE3iayMeta?.alias || [],
    redirect: officialSubscriptionConfirmationPagetjJEGE3iayMeta?.redirect || undefined,
    component: () => import("/webapp/pages/officialSubscriptionConfirmationPage.vue").then(m => m.default || m)
  },
  {
    name: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta?.name ?? "onSiteSubscriptionConfirmationPage___en-CA",
    path: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta?.path ?? "/site-subscription-confirmation-page",
    meta: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta || {},
    alias: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta?.alias || [],
    redirect: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta?.redirect || undefined,
    component: () => import("/webapp/pages/onSiteSubscriptionConfirmationPage.vue").then(m => m.default || m)
  },
  {
    name: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta?.name ?? "onSiteSubscriptionConfirmationPage___fr-CA",
    path: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta?.path ?? "/fr-CA/page-confirmation-abonnement-sur-site",
    meta: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta || {},
    alias: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta?.alias || [],
    redirect: onSiteSubscriptionConfirmationPagedD2zA3yF8uMeta?.redirect || undefined,
    component: () => import("/webapp/pages/onSiteSubscriptionConfirmationPage.vue").then(m => m.default || m)
  },
  {
    name: productshvsMC217XqMeta?.name ?? "products___en-CA",
    path: productshvsMC217XqMeta?.path ?? "/products",
    children: [
  {
    name: awayFromHomeMarketxP17CBd3kiMeta?.name ?? "products-awayFromHomeMarket___en-CA",
    path: awayFromHomeMarketxP17CBd3kiMeta?.path ?? "/products/away-from-home-market",
    meta: awayFromHomeMarketxP17CBd3kiMeta || {},
    alias: awayFromHomeMarketxP17CBd3kiMeta?.alias || [],
    redirect: awayFromHomeMarketxP17CBd3kiMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products/awayFromHomeMarket.vue").then(m => m.default || m)
  },
  {
    name: consumerMarketdLuEfUAR7bMeta?.name ?? "products-consumerMarket___en-CA",
    path: consumerMarketdLuEfUAR7bMeta?.path ?? "/products/consumer-market",
    meta: consumerMarketdLuEfUAR7bMeta || {},
    alias: consumerMarketdLuEfUAR7bMeta?.alias || [],
    redirect: consumerMarketdLuEfUAR7bMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products/consumerMarket.vue").then(m => m.default || m)
  },
  {
    name: privateLabelMarketoPBRfrQQGTMeta?.name ?? "products-privateLabelMarket___en-CA",
    path: privateLabelMarketoPBRfrQQGTMeta?.path ?? "/products/private-label-market",
    meta: privateLabelMarketoPBRfrQQGTMeta || {},
    alias: privateLabelMarketoPBRfrQQGTMeta?.alias || [],
    redirect: privateLabelMarketoPBRfrQQGTMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products/privateLabelMarket.vue").then(m => m.default || m)
  },
  {
    name: productsdOqbioQb2vMeta?.name ?? "products-products___en-CA",
    path: productsdOqbioQb2vMeta?.path ?? "/products",
    meta: productsdOqbioQb2vMeta || {},
    alias: productsdOqbioQb2vMeta?.alias || [],
    redirect: productsdOqbioQb2vMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products/products.vue").then(m => m.default || m)
  }
],
    meta: productshvsMC217XqMeta || {},
    alias: productshvsMC217XqMeta?.alias || [],
    redirect: productshvsMC217XqMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products.vue").then(m => m.default || m)
  },
  {
    name: productshvsMC217XqMeta?.name ?? "products___fr-CA",
    path: productshvsMC217XqMeta?.path ?? "/fr-CA/products",
    children: [
  {
    name: awayFromHomeMarketxP17CBd3kiMeta?.name ?? "products-awayFromHomeMarket___fr-CA",
    path: awayFromHomeMarketxP17CBd3kiMeta?.path ?? "/fr-CA/produits/segment-marche-hors-foyer",
    meta: awayFromHomeMarketxP17CBd3kiMeta || {},
    alias: awayFromHomeMarketxP17CBd3kiMeta?.alias || [],
    redirect: awayFromHomeMarketxP17CBd3kiMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products/awayFromHomeMarket.vue").then(m => m.default || m)
  },
  {
    name: consumerMarketdLuEfUAR7bMeta?.name ?? "products-consumerMarket___fr-CA",
    path: consumerMarketdLuEfUAR7bMeta?.path ?? "/fr-CA/produits/marche-de-consommation",
    meta: consumerMarketdLuEfUAR7bMeta || {},
    alias: consumerMarketdLuEfUAR7bMeta?.alias || [],
    redirect: consumerMarketdLuEfUAR7bMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products/consumerMarket.vue").then(m => m.default || m)
  },
  {
    name: privateLabelMarketoPBRfrQQGTMeta?.name ?? "products-privateLabelMarket___fr-CA",
    path: privateLabelMarketoPBRfrQQGTMeta?.path ?? "/fr-CA/produits/segment-marques-privees",
    meta: privateLabelMarketoPBRfrQQGTMeta || {},
    alias: privateLabelMarketoPBRfrQQGTMeta?.alias || [],
    redirect: privateLabelMarketoPBRfrQQGTMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products/privateLabelMarket.vue").then(m => m.default || m)
  },
  {
    name: productsdOqbioQb2vMeta?.name ?? "products-products___fr-CA",
    path: productsdOqbioQb2vMeta?.path ?? "/fr-CA/produits",
    meta: productsdOqbioQb2vMeta || {},
    alias: productsdOqbioQb2vMeta?.alias || [],
    redirect: productsdOqbioQb2vMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products/products.vue").then(m => m.default || m)
  }
],
    meta: productshvsMC217XqMeta || {},
    alias: productshvsMC217XqMeta?.alias || [],
    redirect: productshvsMC217XqMeta?.redirect || undefined,
    component: () => import("/webapp/pages/products.vue").then(m => m.default || m)
  },
  {
    name: sitemapGxgpUpZGsgMeta?.name ?? "sitemap___en-CA",
    path: sitemapGxgpUpZGsgMeta?.path ?? "/sitemap",
    meta: sitemapGxgpUpZGsgMeta || {},
    alias: sitemapGxgpUpZGsgMeta?.alias || [],
    redirect: sitemapGxgpUpZGsgMeta?.redirect || undefined,
    component: () => import("/webapp/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapGxgpUpZGsgMeta?.name ?? "sitemap___fr-CA",
    path: sitemapGxgpUpZGsgMeta?.path ?? "/fr-CA/sitemap",
    meta: sitemapGxgpUpZGsgMeta || {},
    alias: sitemapGxgpUpZGsgMeta?.alias || [],
    redirect: sitemapGxgpUpZGsgMeta?.redirect || undefined,
    component: () => import("/webapp/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sustainableDevelopment4GiT3UHmhEMeta?.name ?? "sustainability-sustainableDevelopment___en-CA",
    path: sustainableDevelopment4GiT3UHmhEMeta?.path ?? "/sustainability/sustainable-development",
    meta: sustainableDevelopment4GiT3UHmhEMeta || {},
    alias: sustainableDevelopment4GiT3UHmhEMeta?.alias || [],
    redirect: sustainableDevelopment4GiT3UHmhEMeta?.redirect || undefined,
    component: () => import("/webapp/pages/sustainability/sustainableDevelopment.vue").then(m => m.default || m)
  },
  {
    name: sustainableDevelopment4GiT3UHmhEMeta?.name ?? "sustainability-sustainableDevelopment___fr-CA",
    path: sustainableDevelopment4GiT3UHmhEMeta?.path ?? "/fr-CA/developpement/developpement-durable",
    meta: sustainableDevelopment4GiT3UHmhEMeta || {},
    alias: sustainableDevelopment4GiT3UHmhEMeta?.alias || [],
    redirect: sustainableDevelopment4GiT3UHmhEMeta?.redirect || undefined,
    component: () => import("/webapp/pages/sustainability/sustainableDevelopment.vue").then(m => m.default || m)
  },
  {
    name: termsConditionsQwoYMgqO0RMeta?.name ?? "termsConditions___en-CA",
    path: termsConditionsQwoYMgqO0RMeta?.path ?? "/terms-conditions",
    meta: termsConditionsQwoYMgqO0RMeta || {},
    alias: termsConditionsQwoYMgqO0RMeta?.alias || [],
    redirect: termsConditionsQwoYMgqO0RMeta?.redirect || undefined,
    component: () => import("/webapp/pages/termsConditions.vue").then(m => m.default || m)
  },
  {
    name: termsConditionsQwoYMgqO0RMeta?.name ?? "termsConditions___fr-CA",
    path: termsConditionsQwoYMgqO0RMeta?.path ?? "/fr-CA/conditions-utilisations",
    meta: termsConditionsQwoYMgqO0RMeta || {},
    alias: termsConditionsQwoYMgqO0RMeta?.alias || [],
    redirect: termsConditionsQwoYMgqO0RMeta?.redirect || undefined,
    component: () => import("/webapp/pages/termsConditions.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeConfirmationPagewU9zZPQ69BMeta?.name ?? "unsubscribeConfirmationPage___en-CA",
    path: unsubscribeConfirmationPagewU9zZPQ69BMeta?.path ?? "/unsubscribe-confirmation-page",
    meta: unsubscribeConfirmationPagewU9zZPQ69BMeta || {},
    alias: unsubscribeConfirmationPagewU9zZPQ69BMeta?.alias || [],
    redirect: unsubscribeConfirmationPagewU9zZPQ69BMeta?.redirect || undefined,
    component: () => import("/webapp/pages/unsubscribeConfirmationPage.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeConfirmationPagewU9zZPQ69BMeta?.name ?? "unsubscribeConfirmationPage___fr-CA",
    path: unsubscribeConfirmationPagewU9zZPQ69BMeta?.path ?? "/fr-CA/page-confirmation-desabonnement",
    meta: unsubscribeConfirmationPagewU9zZPQ69BMeta || {},
    alias: unsubscribeConfirmationPagewU9zZPQ69BMeta?.alias || [],
    redirect: unsubscribeConfirmationPagewU9zZPQ69BMeta?.redirect || undefined,
    component: () => import("/webapp/pages/unsubscribeConfirmationPage.vue").then(m => m.default || m)
  },
  {
    name: vote5RMIYNWZTCMeta?.name ?? "vote___en-CA",
    path: vote5RMIYNWZTCMeta?.path ?? "/agm-vote",
    meta: vote5RMIYNWZTCMeta || {},
    alias: vote5RMIYNWZTCMeta?.alias || [],
    redirect: vote5RMIYNWZTCMeta?.redirect || undefined,
    component: () => import("/webapp/pages/vote.vue").then(m => m.default || m)
  },
  {
    name: vote5RMIYNWZTCMeta?.name ?? "vote___fr-CA",
    path: vote5RMIYNWZTCMeta?.path ?? "/fr-CA/vote-agm",
    meta: vote5RMIYNWZTCMeta || {},
    alias: vote5RMIYNWZTCMeta?.alias || [],
    redirect: vote5RMIYNWZTCMeta?.redirect || undefined,
    component: () => import("/webapp/pages/vote.vue").then(m => m.default || m)
  }
]