import type { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'

export const useLocale = () => {
  const { locale, locales } = useI18n()

  const availableLocales = computed(() => {
    return (locales.value as LocaleObject[]).filter(i => i.code !== locale.value)
  })

  const switchLocalePath = useSwitchLocalePath()

  const switchLocale = async () => {
    const otherLanguage = availableLocales.value.filter((x : any) => x !== locale.value).at(0)

    if (otherLanguage) {
      await navigateTo(switchLocalePath(otherLanguage.code))
    }
  }

  return { locale, switchLocale }
}
