<script setup lang="ts">
const { locale } = useLocale()
const localePath = useLocalePath()
const { currentRoute } = useRouter()
const { t } = useI18n()

function getAvailableLocalePath() {
  const path = localePath(currentRoute.value.fullPath, locale.value === 'en-CA' ? 'fr-CA' : 'en-CA')
  return path
}

</script>

<template>
  <div class="footer">
    <div class="menuFooter">
      <div class="wrapper clearfix">
        <div class="menuPrincipalFooter left">
          <div class="menuCss2">
            <ul class="MenuBar">
              <li class="item29 first rank0 parent">
                <NuxtLink :to="localePath('about-about')">
                  {{ $t('navigation.about.name') }}
                </NuxtLink>
                <div class="itemChildDiv29">
                  <ul class="itemChild29">
                    <li class="item14 first rank0">
                      <NuxtLink :to="localePath('about-corporateProfile')">
                        {{ $t('navigation.corporateProfile.name') }}
                      </NuxtLink>
                    </li>
                    <li class="item15 rank1">
                      <NuxtLink :to="localePath('about-management')">
                        {{ $t('navigation.management.name') }}
                      </NuxtLink>
                    </li>
                    <li class="item12 rank2">
                      <NuxtLink :to="localePath('about-boardDirectors')">
                        {{ $t('navigation.boardDirectors.name') }}
                      </NuxtLink>
                    </li>
                    <li class="item18 last rank3">
                      <NuxtLink :to="localePath('about-facilities')">
                        {{ $t('navigation.facilities.name') }}
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="item9 rank1 parent">
                <NuxtLink :to="localePath('products')">
                  {{ $t('navigation.products.name') }}
                </NuxtLink>
                <div class="itemChildDiv9">
                  <ul class="itemChild9">
                    <li class="item22 first rank0">
                      <NuxtLink :to="localePath('products-consumerMarket')">
                        {{ $t('navigation.consumerMarket.name') }}
                      </NuxtLink>
                    </li>
                    <li id="c79_1_1"
                        class="item17 rank1">
                      <NuxtLink :to="localePath('products-awayFromHomeMarket')">
                        {{ $t('navigation.awayFromHomeMarket.name') }}
                      </NuxtLink>
                    </li>
                    <li id="c79_1_2"
                        class="item19 last rank2">
                      <NuxtLink :to="localePath('products-privateLabelMarket')">
                        {{ $t('navigation.privateLabelMarket.name') }}
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="item20 selected rank2 parent">
                <NuxtLink :to="localePath('newsEvents')">
                  {{ $t('navigation.newsEvents.name') }}
                </NuxtLink>
                <div class="itemChildDiv20 selected">
                  <ul class="itemChild20 selected">
                    <li id="c79_2_0"
                        class="item21 selected first rank0"
                        expansionmode="HoverExpansion">
                      <NuxtLink :to="localePath('newsEvents-pressReleases')">
                        {{ $t('navigation.pressReleases.name') }}
                      </NuxtLink>
                    </li>
                    <li id="c79_2_1"
                        class="item54 last rank1"
                        expansionmode="HoverExpansion">
                      <NuxtLink :to="localePath('investorRelations-financialDocuments-presentationsWebcasts')">
                        {{ $t('navigation.presentationsWebcasts.name') }}
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li id="c79_3"
                  class="item30 rank3 parent"
                  expansionmode="HoverExpansion">
                <NuxtLink :to="localePath('investorRelations-investorRelations')">
                  {{ $t('navigation.investorRelations.name') }}
                </NuxtLink>
                <div class="itemChildDiv30">
                  <ul class="itemChild30">
                    <li id="c79_3_0"
                        class="item31 first rank0"
                        expansionmode="HoverExpansion">
                      <NuxtLink :to="localePath('investorRelations-financialDocuments-quarterlyReports')">
                        {{ $t('navigation.financialDocuments.name') }}
                      </NuxtLink>
                    </li>
                    <li id="c79_3_1"
                        class="item36 rank1"
                        expansionmode="HoverExpansion">
                      <NuxtLink :to="localePath('investorRelations-shareholderInformation')">
                        {{ $t('navigation.shareholderInformation.name') }}
                      </NuxtLink>
                    </li>
                    <li id="c79_3_2"
                        class="item42 rank2"
                        expansionmode="HoverExpansion">
                      <NuxtLink :to="localePath('investorRelations-corporateGovernance')">
                        {{ $t('navigation.corporateGovernance.name') }}
                      </NuxtLink>
                    </li>
                    <li id="c79_3_3"
                        class="item58 last rank3"
                        expansionmode="HoverExpansion">
                      <NuxtLink :to="localePath('investorRelations-emailAlerts')">
                        {{ $t('navigation.emailAlerts.name') }}
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li id="c79_4"
                  class="item55 last rank4 parent"
                  expansionmode="HoverExpansion">
                <NuxtLink :to="localePath('sustainability-sustainableDevelopment')">
                  {{ $t('navigation.sustainability.name') }}
                </NuxtLink>
                <div class="itemChildDiv55">
                  <ul class="itemChild55">
                    <li id="c79_4_0"
                        class="item45 first last rank0"
                        expansionmode="HoverExpansion">
                      <NuxtLink :to="localePath('sustainability-sustainableDevelopment')">
                        {{ $t('navigation.sustainableDevelopment.name') }}
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="menuUtilitaireFooter left">
          <div class="menuCss6">
            <ul class="MenuBar">
              <li class="item46 first last rank0">
                <NuxtLink :to="localePath('contact')">
                  {{ $t('navigation.contact.name') }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <div class="terms">
            <div class="menuCss3">
              <ul class="MenuBar">
                <li class="item16 first rank0">
                  <NuxtLink :to="localePath('termsConditions')">
                    {{ $t('navigation.termsConditions.name') }}
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="localePath('sitemap')">
                    {{ $t('navigation.sitemap.name') }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
          <div class="langue">
            <div>
              <ul>
                <li>
                  <NuxtLink class="lang"
                            :to="getAvailableLocalePath()">
                    {{ t("home.language") }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyrightfooter">
      <div class="wrapper">
        <div class="copyright">
          {{ t("home.footer.copyright") }}
        </div>
        <div class="nmedio">
          {{ t("home.footer.created") }}&nbsp;<a name="hyperlien"
             href="https://www.nmedia.ca/en-CA/home"
             target="_blank">{{ t("home.footer.nmedia") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
