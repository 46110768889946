<script setup lang="ts">
const { locale } = useLocale()
const localePath = useLocalePath()
const { currentRoute } = useRouter()
const emit = defineEmits(['toggleMenuMobile'])

function getAvailableLocalePath() {
  const path = localePath(currentRoute.value.fullPath, locale.value === 'en-CA' ? 'fr-CA' : 'en-CA')
  return path
}

function btnOpen() {
  emit('openMenuMobile')
}

function isActive(path: string) {
  return currentRoute.value.fullPath.startsWith(localePath(path))
}
</script>

<template>
  <div class="header">
    <div class="wrapperLogo">
      <div class="logo">
        <h1>
          <NuxtLink :to="localePath('index')">
            <img src="~/assets/images/kp-tissue-inc.png"
                 alt="KP-Tissue-Inc">
          </NuxtLink>
        </h1>
      </div>
    </div>
    <div class="headerMobile m">
      <div class="menu">
        <a class="toggleMenuMobile" @click="btnOpen()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
          </svg>
        </a>
      </div>
      <div class="langue">
        <div>
          <ul class="">
            <li class="">
              <NuxtLink :to="getAvailableLocalePath()">
                {{ $t('home.languageMobile') }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="menuUtilitaire clearfix">
      <div class="wrapper">
        <div class="menuCss5">
          <ul class="MenuBar">
            <li class="item6 selected first last rank0">
              <NuxtLink :to="localePath('index')">
                {{ $t('navigation.home.name') }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="langue">
          <div>
            <ul class="">
              <li class="">
                <NuxtLink :to="getAvailableLocalePath()">
                  {{ $t('home.language') }}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="menuPrincipal">
      <div class="wrapper">
        <div class="menuCss1">
          <ul class="MenuBar">
            <li class="item29 first rank0">
              <NuxtLink :to="localePath('about-about')">
                {{ $t('navigation.about.name') }}
              </NuxtLink>
            </li>
            <li class="item9 rank1">
              <NuxtLink :to="localePath('products-products')">
                {{ $t('navigation.products.name') }}
              </NuxtLink>
            </li>
            <li class="item20 rank2">
              <NuxtLink :to="localePath('newsEvents-pressReleases')"
                        :class="{ 'router-link-active': isActive('newsEvents-pressReleases') }">
                {{ $t('navigation.newsEvents.name') }}
              </NuxtLink>
            </li>
            <li class="item30 rank3">
              <NuxtLink :to="localePath('investorRelations-investorRelations')"
                        :class="{ 'router-link-active': isActive('investorRelations-investorRelations') }">
                {{ $t('navigation.investorRelations.name') }}
              </NuxtLink>
            </li>
            <li class="item55 rank4">
              <NuxtLink :to="localePath('sustainability-sustainableDevelopment')">
                {{ $t('navigation.sustainability.name') }}
              </NuxtLink>
            </li>
            <li class="item46 rank5">
              <NuxtLink :to="localePath('contact')">
                {{ $t('navigation.contact.name') }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
