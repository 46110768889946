// @ts-nocheck


export const localeCodes = [
  "en-CA",
  "fr-CA"
]

export const localeMessages = { 
  "en-CA": [
      { key: "../locales/en-ca/about.json", load: () => import("../locales/en-ca/about.json" /* webpackChunkName: "locale__webapp_locales_en_ca_about_json" */), cache: true },
      { key: "../locales/en-ca/annualInformationForms.json", load: () => import("../locales/en-ca/annualInformationForms.json" /* webpackChunkName: "locale__webapp_locales_en_ca_annualInformationForms_json" */), cache: true },
      { key: "../locales/en-ca/annualReports.json", load: () => import("../locales/en-ca/annualReports.json" /* webpackChunkName: "locale__webapp_locales_en_ca_annualReports_json" */), cache: true },
      { key: "../locales/en-ca/boardDirectors.json", load: () => import("../locales/en-ca/boardDirectors.json" /* webpackChunkName: "locale__webapp_locales_en_ca_boardDirectors_json" */), cache: true },
      { key: "../locales/en-ca/contact.json", load: () => import("../locales/en-ca/contact.json" /* webpackChunkName: "locale__webapp_locales_en_ca_contact_json" */), cache: true },
      { key: "../locales/en-ca/corporateGouvernance.json", load: () => import("../locales/en-ca/corporateGouvernance.json" /* webpackChunkName: "locale__webapp_locales_en_ca_corporateGouvernance_json" */), cache: true },
      { key: "../locales/en-ca/error.json", load: () => import("../locales/en-ca/error.json" /* webpackChunkName: "locale__webapp_locales_en_ca_error_json" */), cache: true },
      { key: "../locales/en-ca/global.json", load: () => import("../locales/en-ca/global.json" /* webpackChunkName: "locale__webapp_locales_en_ca_global_json" */), cache: true },
      { key: "../locales/en-ca/home.json", load: () => import("../locales/en-ca/home.json" /* webpackChunkName: "locale__webapp_locales_en_ca_home_json" */), cache: true },
      { key: "../locales/en-ca/informationRequestConfirmation.json", load: () => import("../locales/en-ca/informationRequestConfirmation.json" /* webpackChunkName: "locale__webapp_locales_en_ca_informationRequestConfirmation_json" */), cache: true },
      { key: "../locales/en-ca/login.json", load: () => import("../locales/en-ca/login.json" /* webpackChunkName: "locale__webapp_locales_en_ca_login_json" */), cache: true },
      { key: "../locales/en-ca/managementProxyCirculars.json", load: () => import("../locales/en-ca/managementProxyCirculars.json" /* webpackChunkName: "locale__webapp_locales_en_ca_managementProxyCirculars_json" */), cache: true },
      { key: "../locales/en-ca/navigation.json", load: () => import("../locales/en-ca/navigation.json" /* webpackChunkName: "locale__webapp_locales_en_ca_navigation_json" */), cache: true },
      { key: "../locales/en-ca/officialSubscriptionConfirmation.json", load: () => import("../locales/en-ca/officialSubscriptionConfirmation.json" /* webpackChunkName: "locale__webapp_locales_en_ca_officialSubscriptionConfirmation_json" */), cache: true },
      { key: "../locales/en-ca/presentationsWebcasts.json", load: () => import("../locales/en-ca/presentationsWebcasts.json" /* webpackChunkName: "locale__webapp_locales_en_ca_presentationsWebcasts_json" */), cache: true },
      { key: "../locales/en-ca/pressReleases.json", load: () => import("../locales/en-ca/pressReleases.json" /* webpackChunkName: "locale__webapp_locales_en_ca_pressReleases_json" */), cache: true },
      { key: "../locales/en-ca/quaterlyReports.json", load: () => import("../locales/en-ca/quaterlyReports.json" /* webpackChunkName: "locale__webapp_locales_en_ca_quaterlyReports_json" */), cache: true },
      { key: "../locales/en-ca/sitemap.json", load: () => import("../locales/en-ca/sitemap.json" /* webpackChunkName: "locale__webapp_locales_en_ca_sitemap_json" */), cache: true },
      { key: "../locales/en-ca/subscriptionConfirmation.json", load: () => import("../locales/en-ca/subscriptionConfirmation.json" /* webpackChunkName: "locale__webapp_locales_en_ca_subscriptionConfirmation_json" */), cache: true },
      { key: "../locales/en-ca/terms.json", load: () => import("../locales/en-ca/terms.json" /* webpackChunkName: "locale__webapp_locales_en_ca_terms_json" */), cache: true },
      { key: "../locales/en-ca/unsubscribeConfirmation.json", load: () => import("../locales/en-ca/unsubscribeConfirmation.json" /* webpackChunkName: "locale__webapp_locales_en_ca_unsubscribeConfirmation_json" */), cache: true },
      { key: "../locales/en-ca/validation.json", load: () => import("../locales/en-ca/validation.json" /* webpackChunkName: "locale__webapp_locales_en_ca_validation_json" */), cache: true },
      { key: "../locales/en-ca/variables.json", load: () => import("../locales/en-ca/variables.json" /* webpackChunkName: "locale__webapp_locales_en_ca_variables_json" */), cache: true },
  ],
  "fr-CA": [
      { key: "../locales/fr-ca/about.json", load: () => import("../locales/fr-ca/about.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_about_json" */), cache: true },
      { key: "../locales/fr-ca/annualInformationForms.json", load: () => import("../locales/fr-ca/annualInformationForms.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_annualInformationForms_json" */), cache: true },
      { key: "../locales/fr-ca/annualReports.json", load: () => import("../locales/fr-ca/annualReports.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_annualReports_json" */), cache: true },
      { key: "../locales/fr-ca/boardDirectors.json", load: () => import("../locales/fr-ca/boardDirectors.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_boardDirectors_json" */), cache: true },
      { key: "../locales/fr-ca/contact.json", load: () => import("../locales/fr-ca/contact.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_contact_json" */), cache: true },
      { key: "../locales/fr-ca/corporateGouvernance.json", load: () => import("../locales/fr-ca/corporateGouvernance.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_corporateGouvernance_json" */), cache: true },
      { key: "../locales/fr-ca/error.json", load: () => import("../locales/fr-ca/error.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_error_json" */), cache: true },
      { key: "../locales/fr-ca/global.json", load: () => import("../locales/fr-ca/global.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_global_json" */), cache: true },
      { key: "../locales/fr-ca/home.json", load: () => import("../locales/fr-ca/home.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_home_json" */), cache: true },
      { key: "../locales/fr-ca/informationRequestConfirmation.json", load: () => import("../locales/fr-ca/informationRequestConfirmation.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_informationRequestConfirmation_json" */), cache: true },
      { key: "../locales/fr-ca/login.json", load: () => import("../locales/fr-ca/login.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_login_json" */), cache: true },
      { key: "../locales/fr-ca/managementProxyCirculars.json", load: () => import("../locales/fr-ca/managementProxyCirculars.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_managementProxyCirculars_json" */), cache: true },
      { key: "../locales/fr-ca/navigation.json", load: () => import("../locales/fr-ca/navigation.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_navigation_json" */), cache: true },
      { key: "../locales/fr-ca/officialSubscriptionConfirmation.json", load: () => import("../locales/fr-ca/officialSubscriptionConfirmation.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_officialSubscriptionConfirmation_json" */), cache: true },
      { key: "../locales/fr-ca/presentationsWebcasts.json", load: () => import("../locales/fr-ca/presentationsWebcasts.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_presentationsWebcasts_json" */), cache: true },
      { key: "../locales/fr-ca/pressReleases.json", load: () => import("../locales/fr-ca/pressReleases.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_pressReleases_json" */), cache: true },
      { key: "../locales/fr-ca/quaterlyReports.json", load: () => import("../locales/fr-ca/quaterlyReports.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_quaterlyReports_json" */), cache: true },
      { key: "../locales/fr-ca/sitemap.json", load: () => import("../locales/fr-ca/sitemap.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_sitemap_json" */), cache: true },
      { key: "../locales/fr-ca/subscriptionConfirmation.json", load: () => import("../locales/fr-ca/subscriptionConfirmation.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_subscriptionConfirmation_json" */), cache: true },
      { key: "../locales/fr-ca/terms.json", load: () => import("../locales/fr-ca/terms.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_terms_json" */), cache: true },
      { key: "../locales/fr-ca/unsubscribeConfirmation.json", load: () => import("../locales/fr-ca/unsubscribeConfirmation.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_unsubscribeConfirmation_json" */), cache: true },
      { key: "../locales/fr-ca/validation.json", load: () => import("../locales/fr-ca/validation.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_validation_json" */), cache: true },
      { key: "../locales/fr-ca/variables.json", load: () => import("../locales/fr-ca/variables.json" /* webpackChunkName: "locale__webapp_locales_fr_ca_variables_json" */), cache: true },
  ],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "i18n.config.ts",
  "locales": [
    {
      "name": "English",
      "code": "en-CA",
      "files": [
        "locales/en-ca/about.json",
        "locales/en-ca/annualInformationForms.json",
        "locales/en-ca/annualReports.json",
        "locales/en-ca/boardDirectors.json",
        "locales/en-ca/contact.json",
        "locales/en-ca/corporateGouvernance.json",
        "locales/en-ca/error.json",
        "locales/en-ca/global.json",
        "locales/en-ca/home.json",
        "locales/en-ca/informationRequestConfirmation.json",
        "locales/en-ca/login.json",
        "locales/en-ca/managementProxyCirculars.json",
        "locales/en-ca/navigation.json",
        "locales/en-ca/officialSubscriptionConfirmation.json",
        "locales/en-ca/presentationsWebcasts.json",
        "locales/en-ca/pressReleases.json",
        "locales/en-ca/quaterlyReports.json",
        "locales/en-ca/sitemap.json",
        "locales/en-ca/subscriptionConfirmation.json",
        "locales/en-ca/terms.json",
        "locales/en-ca/unsubscribeConfirmation.json",
        "locales/en-ca/validation.json",
        "locales/en-ca/variables.json"
      ]
    },
    {
      "name": "Français",
      "code": "fr-CA",
      "files": [
        "locales/fr-ca/about.json",
        "locales/fr-ca/annualInformationForms.json",
        "locales/fr-ca/annualReports.json",
        "locales/fr-ca/boardDirectors.json",
        "locales/fr-ca/contact.json",
        "locales/fr-ca/corporateGouvernance.json",
        "locales/fr-ca/error.json",
        "locales/fr-ca/global.json",
        "locales/fr-ca/home.json",
        "locales/fr-ca/informationRequestConfirmation.json",
        "locales/fr-ca/login.json",
        "locales/fr-ca/managementProxyCirculars.json",
        "locales/fr-ca/navigation.json",
        "locales/fr-ca/officialSubscriptionConfirmation.json",
        "locales/fr-ca/presentationsWebcasts.json",
        "locales/fr-ca/pressReleases.json",
        "locales/fr-ca/quaterlyReports.json",
        "locales/fr-ca/sitemap.json",
        "locales/fr-ca/subscriptionConfirmation.json",
        "locales/fr-ca/terms.json",
        "locales/fr-ca/unsubscribeConfirmation.json",
        "locales/fr-ca/validation.json",
        "locales/fr-ca/variables.json"
      ]
    }
  ],
  "defaultLocale": "en-CA",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales/",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "dateTimeFormats": {
    "en-CA": {
      "shortDate": {
        "year": "numeric",
        "month": "long",
        "day": "numeric"
      }
    },
    "fr-CA": {
      "shortDate": {
        "year": "numeric",
        "month": "long",
        "day": "numeric"
      }
    }
  },
  "i18nModules": []
}
  
  const vueI18nConfigLoader = async loader => {
    const config = await loader().then(r => r.default || r)
    if (typeof config === 'object') return config
    if (typeof config === 'function') return await config()
    return {}
  }

  const deepCopy = (src, des, predicate) => {
    for (const key in src) {
      if (typeof src[key] === 'object') {
        if (!(typeof des[key] === 'object')) des[key] = {}
        deepCopy(src[key], des[key], predicate)
      } else {
        if (predicate) {
          if (predicate(src[key], des[key])) {
            des[key] = src[key]
          }
        } else {
          des[key] = src[key]
        }
      }
    }
  }
  
  const mergeVueI18nConfigs = async (loader) => {
    const layerConfig = await vueI18nConfigLoader(loader)
    const cfg = layerConfig || {}
    
    for (const [k, v] of Object.entries(cfg)) {
      if(nuxtI18nOptions.vueI18n?.[k] === undefined || typeof nuxtI18nOptions.vueI18n?.[k] !== 'object') {
        nuxtI18nOptions.vueI18n[k] = v
      } else {
        deepCopy(v, nuxtI18nOptions.vueI18n[k])
      }
    }
  }

  nuxtI18nOptions.vueI18n = { messages: {} }
  
  await mergeVueI18nConfigs(() => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: i18n_config_ts_bffaebcb */)) 
    
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "name": "English",
      "code": "en-CA",
      "files": [
        {
          "path": "locales/en-ca/about.json"
        },
        {
          "path": "locales/en-ca/annualInformationForms.json"
        },
        {
          "path": "locales/en-ca/annualReports.json"
        },
        {
          "path": "locales/en-ca/boardDirectors.json"
        },
        {
          "path": "locales/en-ca/contact.json"
        },
        {
          "path": "locales/en-ca/corporateGouvernance.json"
        },
        {
          "path": "locales/en-ca/error.json"
        },
        {
          "path": "locales/en-ca/global.json"
        },
        {
          "path": "locales/en-ca/home.json"
        },
        {
          "path": "locales/en-ca/informationRequestConfirmation.json"
        },
        {
          "path": "locales/en-ca/login.json"
        },
        {
          "path": "locales/en-ca/managementProxyCirculars.json"
        },
        {
          "path": "locales/en-ca/navigation.json"
        },
        {
          "path": "locales/en-ca/officialSubscriptionConfirmation.json"
        },
        {
          "path": "locales/en-ca/presentationsWebcasts.json"
        },
        {
          "path": "locales/en-ca/pressReleases.json"
        },
        {
          "path": "locales/en-ca/quaterlyReports.json"
        },
        {
          "path": "locales/en-ca/sitemap.json"
        },
        {
          "path": "locales/en-ca/subscriptionConfirmation.json"
        },
        {
          "path": "locales/en-ca/terms.json"
        },
        {
          "path": "locales/en-ca/unsubscribeConfirmation.json"
        },
        {
          "path": "locales/en-ca/validation.json"
        },
        {
          "path": "locales/en-ca/variables.json"
        }
      ]
    },
    {
      "name": "Français",
      "code": "fr-CA",
      "files": [
        {
          "path": "locales/fr-ca/about.json"
        },
        {
          "path": "locales/fr-ca/annualInformationForms.json"
        },
        {
          "path": "locales/fr-ca/annualReports.json"
        },
        {
          "path": "locales/fr-ca/boardDirectors.json"
        },
        {
          "path": "locales/fr-ca/contact.json"
        },
        {
          "path": "locales/fr-ca/corporateGouvernance.json"
        },
        {
          "path": "locales/fr-ca/error.json"
        },
        {
          "path": "locales/fr-ca/global.json"
        },
        {
          "path": "locales/fr-ca/home.json"
        },
        {
          "path": "locales/fr-ca/informationRequestConfirmation.json"
        },
        {
          "path": "locales/fr-ca/login.json"
        },
        {
          "path": "locales/fr-ca/managementProxyCirculars.json"
        },
        {
          "path": "locales/fr-ca/navigation.json"
        },
        {
          "path": "locales/fr-ca/officialSubscriptionConfirmation.json"
        },
        {
          "path": "locales/fr-ca/presentationsWebcasts.json"
        },
        {
          "path": "locales/fr-ca/pressReleases.json"
        },
        {
          "path": "locales/fr-ca/quaterlyReports.json"
        },
        {
          "path": "locales/fr-ca/sitemap.json"
        },
        {
          "path": "locales/fr-ca/subscriptionConfirmation.json"
        },
        {
          "path": "locales/fr-ca/terms.json"
        },
        {
          "path": "locales/fr-ca/unsubscribeConfirmation.json"
        },
        {
          "path": "locales/fr-ca/validation.json"
        },
        {
          "path": "locales/fr-ca/variables.json"
        }
      ]
    }
  ]
}
 
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
