<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { locale: currentLocale, t } = useI18n()
const localePath = useLocalePath()

useHead({
  htmlAttrs: {
    lang: currentLocale
  },
  title: t('error.metaTitle'),
  meta: [{ name: 'description', content: t('error.metaDescription') }]
})
</script>

<template>
  <div>
    <div class="wrapAll interne">
      <MainHeader />
      <div class="wrapContent">
        <div class="entetePage">
          <div class="wrapper">
            <div class="filAriane">
              <span class="">
                <NuxtLink :to="localePath('index')">{{ $t('navigation.home.name') }}</NuxtLink>
                <span class=""> &gt; </span>
                <span class="">{{ $t('navigation.anErrorOccurred.name') }}</span>
              </span>
            </div>
            <div class="title">
              <h1><span class="">{{ $t('navigation.anErrorOccurred.name') }}</span></h1>
            </div>
          </div>
        </div>
        <div class="contentPage">
          <div class="wrapper">
            <div class="sidebar left" />
            <div class="contenu right">
              <div v-if="$i18n.locale == 'en-CA'"
                   class="text">
                <p>Sorry, the page you are looking for no longer exists or has been moved.</p>
                <p>You can try one of the following options: </p>
                <p>
                  <NuxtLink :to="localePath('sitemap')">
                    See the sitemap
                  </NuxtLink>
                  <span> | </span>
                  <NuxtLink :to="localePath('index')">
                    Go back to home page
                  </NuxtLink>
                </p>
              </div>
              <div v-if="$i18n.locale == 'fr-CA'"
                   class="text">
                <p>Il semble que la page à laquelle vous tentez d’accéder n’existe plus ou a été déplacée.</p>
                <p>Avez-vous essayé une des options suivantes?</p>
                <p>
                  <NuxtLink :to="localePath('sitemap')">
                    Accéder au plan du site
                  </NuxtLink>
                  <span> | </span>
                  <NuxtLink :to="localePath('index')">
                    Retourner à la page d'accueil
                  </NuxtLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter />
  </div>
</template>

<style lang="scss" scoped></style>
