<script setup lang="ts">

</script>

<template>
  <header>
    <MainNavigation />
  </header>
</template>

<style lang="scss">
</style>
