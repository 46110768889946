<script setup lang="ts">

</script>

<template>
  <footer>
    <FooterNavigation />
  </footer>
</template>

<style lang="scss">

</style>
